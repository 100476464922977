import locator from 'utils/locator';
import { continueShoppingUrlSelector } from 'redux/modules/login/selectors';
import { useSelector } from 'react-redux';
import urls from 'constants/urls';
import { useCallback } from 'react';

export const useCampaignModalUrlControl = () => {
  const continueShoppingUrl = useSelector(continueShoppingUrlSelector);
  return useCallback(
    (givenPages: string[]) => {
      const currentPage = locator.pathname === urls.signIn ? continueShoppingUrl : locator.pathname;
      return !!givenPages.find(page => currentPage === page);
    },
    [continueShoppingUrl],
  );
};
